import React from 'react';
import Layout from '../components/Layout';
import pic1 from '../assets/images/pic01.jpg';
const BlogPost = () => (
  <Layout fullMenu>
    <section>
      <div>
        <header></header>
        <div className="blog-wrap">
          <div className="blog-space" />
          <h1 className="major">PAGE.TITLE </h1>
          <h3>
            page meta description goes here. the placeholder text for this page
            is the great Common Sense by Thomas Paine. If you haven't yet, read
            it. If you have, read it again.{' '}
          </h3>

          <p>
            Society in every state is a blessing, but Government, even in its
            best state, is but a necessary evil; in its worst state an
            intolerable one: for when we suffer, or are exposed to the same
            miseries BY A GOVERNMENT, which we might expect in a country WITHOUT
            GOVERNMENT, our calamity is heightened by reflecting that we furnish
            the means by which we suffer. Government, like dress, is the badge
            of lost innocence; the palaces of kings are built upon the ruins of
            the bowers of paradise. For were the impulses of conscience clear,
            uniform and irresistibly obeyed, man would need no other lawgiver;
            but that not being the case, he finds it necessary to surrender up a
            part of his property to furnish means for the protection of the
            rest; and this he is induced to do by the same prudence which in
            every other case advises him, out of two evils to choose the least.
            Wherefore, security being the true design and end of government, it
            unanswerably follows that whatever form thereof appears most likely
            to ensure it to us, with the least expense and greatest benefit, is
            preferable to all others.
            <span className="image left">
              <img src={pic1} alt="" />
            </span>
            In order to gain a clear and just idea of the design and end of
            government, let us suppose a small number of persons settled in some
            sequestered part of the earth, unconnected with the rest; they will
            then represent the first peopling of any country, or of the world.
            In this state of natural liberty, society will be their first
            thought. A thousand motives will excite them thereto; the strength
            of one man is so unequal to his wants, and his mind so unfitted for
            perpetual solitude, that he is soon obliged to seek assistance and
            relief of another, who in his turn requires the same.
            <blockquote>
              The psychotherapist, however, must understand not only the
              patient; it is equally important that he should understand
              himself. For that reason the sine quo non is the analysis of the
              analyst, what is called the training analysis. The patient's
              treatment begins with the doctor, so to speak. Only if the doctor
              knows how to cope with himself and his own problems will he be
              able to teach the patient to do the same. Only then. In the
              training analysis the doctor must learn to know his own psyche;
              and to take it seriously. If he cannot do that, the patient will
              not learn either. He will lose a portion of his psyche, just as
              the doctor has lost that portion of his psyche which he has not
              learned to understand. It is not enough, therefore, for the
              training analysis to consist in acquiring a system of concepts.
              The analysand must realize that it concerns himself, that the
              training analysis is a bit of real life and is not a method which
              can be learned by rote. The student who does not grasp that fact
              in his own training analysis will have to pay dearly for the
              failure later on.
            </blockquote>
            Four or five united would be able to raise a tolerable dwelling in
            the midst of a wilderness, but one man might labour out the common
            period of life without accomplishing any thing; when he had felled
            his timber he could not remove it, nor erect it after it was
            removed; hunger in the mean time would urge him to quit his work,
            and every different want would call him a different way. Disease,
            nay even misfortune, would be death; for, though neither might be
            mortal, yet either would disable him from living, and reduce him to
            a state in which he might rather be said to perish than to die.
          </p>
          <p>
            {' '}
            <span className="image right">
              <img src={pic1} alt="" />
            </span>
            Thus necessity, like a gravitating power, would soon form our newly
            arrived emigrants into society, the reciprocal blessings of which
            would supersede, and render the obligations of law and government
            unnecessary while they remained perfectly just to each other; but as
            nothing but Heaven is impregnable to vice, it will unavoidably
            happen that in proportion as they surmount the first difficulties of
            emigration, which bound them together in a common cause, they will
            begin to relax in their duty and attachment to each other: and this
            remissness will point out the necessity of establishing some form of
            government to supply the defect of moral virtue.
          </p>
          <ul className="actions">
            <li>
              <a href="/Blog" className="blog-button">
                More Articles
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </Layout>
);
export default BlogPost;
